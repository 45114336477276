@import 'variables';

.toast-container {
  position: fixed;
  overflow-x: hidden;
  bottom: 1rem;
  right: 1rem;
}

.toast {
  opacity: 0.9;
  border-radius: $border-radius-small;
  color: $white;
  animation: slideInRight 0.2s;

  :global {
    p {
      margin-bottom: 0.25rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: lighten($link-color, 35);
    }
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

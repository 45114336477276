@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.product-card {
  overflow: hidden;
  border-radius: $border-radius-standard;

  &:global(.stack-view) {
    min-height: 22.25rem;
  }

  &:global(.alt-card) {
    width: 320px;
    min-width: 320px;
    border: 1px solid $border-colour;
  }

  :global {
    .card-header {
      padding-bottom: 0.75rem;
    }

    .card-footer {
      padding-top: 0.75rem;
    }
  }

  .title {
    @extend .subtitle-font;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    > a {
      @extend .primary-link;

      height: 2.25rem;
      line-height: 1.1rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}

.table {
  margin-bottom: 0;
  height: fit-content;

  th,
  td {
    border: none;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: normal;

    &.subtle {
      color: $subtle;
    }
  }

  th {
    padding-right: 0.75rem;
    min-width: 4.75rem;
  }

  td {
    max-width: 0;
    width: 100%;
  }
}

.left-col,
.right-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-col {
  flex-grow: 1;
  align-items: center;
}

.right-col {
  align-items: flex-end;
}

.img-wrapper {
  height: 5.5rem;
  width: 5.5rem;
}

.img {
  object-fit: contain;
  width: 5.5rem;
  min-width: 5.5rem;
  height: 5.5rem;
  max-height: 5.5rem;
  border: 1px solid $base-colour-lightest;
  border-radius: $border-radius-small;
  padding: 0;
}

.chemical-structure-wrapper {
  svg {
    border: 1px solid $base-colour-lightest;
    border-radius: $border-radius-small;
  }
}

.price-per-unit {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $subtle;
}

.footer-content {
  display: flex;
  flex-direction: column;
  gap: 0;
  padding-top: 0.75rem;
  border-top: 1px solid $base-colour-lighter;

  .supplier {
    @extend .subtitle-font;

    color: $body-color;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 0.5rem;
    flex-grow: 1;
    width: fit-content;
    display: flex;
    align-items: baseline;
  }

  .upper-footer {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .lower-footer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &:global(.alt-card) {
    align-items: end;
    width: 100%;

    .supplier {
      min-width: 20%;
      max-width: 40%;
    }

    .price-per-unit {
      display: inline-block;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
      width: auto;
      min-width: 50%;
      max-width: 75%;
      color: revert;
    }

    .left-col {
      justify-content: flex-start;
      align-items: flex-end;
      min-width: 50%;
      width: 100%;
    }

    .right-col {
      min-width: auto;
    }
  }

  .left-col {
    justify-content: end;
    align-items: flex-start;
  }

  .right-col {
    width: 30%;
    text-align: end;
  }

  .view-details-button {
    width: 100%;
  }
}

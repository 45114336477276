@import '../../../common/assets/scss/variables';

.panel {
  background-color: $white;
  border-radius: $border-radius-small;
  border: 1px solid $border-colour;
  display: flex;
  flex-direction: column;
}

.panel-header {
  padding: 1.25rem;
  padding-bottom: 0;
}

.panel-body {
  padding: 1.25rem;
  flex-grow: 1;
  align-items: start;
}

.panel-footer {
  padding: 1.25rem;
  border-top: 1px solid $border-colour;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 0;
}

@import '../../../common/assets/scss/variables';

.orders-and-items {
  margin: 2rem 0 2rem 0;
}

.widgets {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}

.chart-widget {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 32.5rem;
  justify-content: center;
  align-items: center;
  border: 1px solid $border-colour;
  border-radius: $border-radius-standard;
  padding: 1.25rem;

  .title {
    font-weight: 400;
    color: $primary-a;
    margin-bottom: 1.25rem;
    text-align: left;
    width: 100%;
  }

  &.mobile {
    max-width: 100%;
  }
}

.chart-wrapper {
  width: 100%;
  min-height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.charts {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  column-gap: 1.25rem;
  row-gap: 1.25rem;
}

.chart {
  width: 100%;
  height: 15rem;
  max-width: 25.25rem;
}

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';

.sort {
  min-width: 11.5rem;
}

.filter-controls-row {
  row-gap: 1rem;
  margin-bottom: 1rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    padding-bottom: 1.75rem;
  }
}

.filter-controls {
  display: flex;
  flex-direction: row;
  column-gap: 2.5rem;
  justify-content: flex-end;
  align-items: center;
}

.daterange-filter {
  width: 100%;

  @include media-breakpoint-up(md) {
    min-width: 15rem;
    max-width: fit-content;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
}

.items-view {
  @include media-breakpoint-up(md) {
    border-top: 1px solid $border-colour;
    padding-top: 1.5rem;
  }
}

.summary {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    row-gap: 1rem;
    flex-direction: row;
    min-height: 2.5rem;
    margin-bottom: 1.5rem;
  }
}

.sort-wrapper {
  padding-bottom: 1.75rem;
  margin-bottom: 1.75rem;
  border-bottom: 1px solid $border-colour;

  @include media-breakpoint-up(md) {
    padding: 0;
    margin: 0;
    border: none;
  }
}

.count-label {
  min-height: 2.5rem;
  font-size: $body-font-size;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    margin: 0;
    border: none;
  }
}

@import '../../../common/assets/scss/variables';

.bottom-toolbar {
  height: $bottom-toolbar-height;
  background-color: $tint-b;
  justify-content: space-between;
  padding: 0 0.5rem;
  z-index: 1046;

  :global {
    .btn {
      height: $bottom-toolbar-height;
      color: $body-color;
      background-color: $tint-b;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: $bottom-toolbar-height;
      padding: 0;
      border: none;
      border-bottom: 0.5rem solid transparent;
      border-top: 0.5rem solid transparent;

      &:hover {
        background-color: $hover-b;
        border-bottom-color: transparent;
        border-top-color: transparent;
        box-shadow: none;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .btn.active {
      border-top-color: $primary-b;
    }

    .badge {
      font-size: 0.5rem;
    }
  }
}

.offcanvas-menu {
  bottom: $bottom-toolbar-height;
  background-color: $tint-b;

  &:global(.offcanvas.offcanvas-bottom) {
    border: none;
    height: calc(100dvh - $bottom-toolbar-height - $main-header-height);
  }

  .offcanvas-body {
    padding: 0 1rem;

    p {
      font-size: $body-font-size;
    }
  }

  .offcanvas-header {
    padding: 1rem 0.5rem;
    padding-left: 1.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title {
    margin-bottom: 0;
    font-size: 1.5rem;
  }
}

@import '../../../common/assets/scss/variables';

.purchased-products-top {
  padding: 1.25rem 0;
  border-top: 1px solid $border-colour;
}

.purchased-product {
  padding-top: 1.25rem;
  margin: 0 0.625rem;
}

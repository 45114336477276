@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';
@import '../../../common/assets/scss/typography';

.order-request {
  width: 100%;
  margin-bottom: 2rem;
  font-size: $body-font-size;
  position: sticky;
  top: 2rem;
}

.header {
  font-size: 1rem;
  margin-bottom: 0.625rem;

  h4 {
    @extend .subtitle-font;

    color: $primary-a;
  }
}

.body {
  padding-bottom: 0.3125rem;
}

.supplier,
.footer {
  margin-bottom: 1.25rem;

  p {
    padding-left: 0.625rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
}

.supplier-header {
  display: flex;
  margin-bottom: 0.5rem;

  h6 {
    font-family: $be-vietnam-pro;
    font-size: $body-font-size;
    color: $primary;
    font-weight: 400;
    margin-bottom: 0;
  }

  span {
    display: flex;
  }

  .warning {
    font-size: 1rem;
    color: $warning;
  }
}

.footer {
  border-top: none;
  margin: 0;
  padding-top: 0;
}

.total {
  font-size: 1rem;
  padding-top: 0.75rem;
  margin-bottom: 1.25rem;
  border-top: 1px solid $border-colour;
}

.create {
  min-height: $ma-controls-min-height;
  width: 100%;
}

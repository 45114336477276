@import '../../../common/assets/scss/variables';

.favourite-btn {
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
  width: fit-content;
  aspect-ratio: 1 / 1;
  color: $subtle;
  margin-left: 1rem;

  &:global(.btn) {
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      color: $subtle;
      background-color: transparent;
      box-shadow: none;
      outline: none;
      border: none;
    }
  }

  &.checked {
    color: $base-colour-gold;

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      color: $base-colour-gold;
    }
  }

  svg {
    vertical-align: top;
  }
}

@import 'variables';

.ma-checkbox {
  min-width: 1rem;
  min-height: 1rem;
  width: 1rem;
  height: 1rem;
  border: 1px solid $border-colour;
  border-radius: $border-radius-small;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;

  &[type='button'] {
    cursor: default;
  }

  &:focus,
  &:focus-visible {
    outline: none;
    border-color: $primary;
  }

  > span {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: inherit;
    height: inherit;
  }
}

@use 'sass:map';

@import '../../../common/assets/scss/SearchPage.module.scss';

.open-request-btn {
  background-color: $white;
  width: fit-content;
  margin-inline: 0.75rem;
  height: $ma-controls-min-height;
}

@container ma-page (width < #{map.get($grid-breakpoints, "md")}) {
  .open-request-btn {
    display: none;
  }
}

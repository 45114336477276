@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.task-card {
  margin-bottom: 1.25rem;
  border: 1px solid $border-colour;

  .header {
    @extend .body-font;

    color: $primary-b;
    padding-bottom: 0.75rem;
  }

  .footer {
    @extend .label-font;

    padding-top: 0.75rem;
  }
}

@import 'variables';

.ma-action-menu {
  &.btn {
    color: inherit;
    background-color: transparent;
    border-color: transparent;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;

    &:active,
    &:hover,
    &:focus,
    &:focus-visible:active {
      color: inherit;
      outline: none;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
  }

  .dropdown-item {
    font-size: $body-font-size;
  }
}

.ma-action-menu-content {
  padding: 0.5rem 0.25rem;
  border: 1px solid $border-colour;
  border-radius: $border-radius-small;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  background-color: $white;
}

.ma-action-menu-item {
  font-size: $body-font-size;
  line-height: 1;
  color: $body-color;
  border-radius: $border-radius-small;
  padding: 0.5rem 1rem;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &[data-disabled] {
    color: $subtle;
    pointer-events: none;
  }

  &[data-highlighted] {
    outline: none;
    background-color: $primary-a;
    color: $white;
  }
}

@import '../../../common/assets/scss/variables';

.summary {
  min-height: 2.5rem;

  h6 {
    font-family: $be-vietnam-pro;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  p,
  li {
    font-size: $body-font-size;
    margin-bottom: 0;
    text-wrap: pretty;
  }

  ul {
    margin-top: 2rem;
  }
}

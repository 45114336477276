@import '../../../common/assets/scss/SearchPage.module.scss';

.sorting {
  align-items: center;
  justify-self: flex-end;
  column-gap: 2.5rem;
  min-width: 16rem;

  &.mobile {
    align-items: normal;
    justify-self: auto;
  }
}

@import 'variables';

.content {
  position: relative;
  z-index: 1100;
  border-radius: $border-radius-small;
  overflow: hidden;
  width: fit-content;
  border: 1px solid $border-color;
  background-color: $white;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state='closed'] {
    display: none;
  }

  &[data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
}

@keyframes slideUpAndFade {
  0% {
    opacity: 0;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

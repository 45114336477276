@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';
@import '../../../common/assets/scss/ma-controls';

.order-item-card {
  overflow: hidden;
  background-color: $white;
  margin-bottom: 1.5rem;

  .footer {
    padding-top: 1.25rem;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;

    > * {
      width: 50%;

      &:global(.btn) {
        min-height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:global(.btn-light) {
        border-color: $primary-a;
        color: $primary-a;
        background-color: $white;
        align-items: center;

        &:disabled {
          border-color: $subtle;
          color: $subtle;
        }
      }
    }
  }

  &.compact {
    .footer {
      flex-direction: column;
      row-gap: 0.625rem;

      > * {
        width: auto;
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > a,
  span {
    @extend .subtitle-font;

    text-decoration: none;
    line-height: 1.2rem;
    height: 2.25rem;
    color: $body-color;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  :global(.btn) {
    margin-left: 1.5rem;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 0.5rem;
}

.info {
  flex-grow: 1;
}

.table {
  overflow: visible;
  margin-bottom: 0;

  th,
  td {
    border: none;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: normal;
  }

  th {
    padding-right: 0.5rem;
    text-align: right;
    color: $subtle;
  }

  td {
    max-width: 0;
    width: 100%;
  }

  tr:last-child td {
    overflow: visible;
    text-overflow: initial;
  }
}

th.supplier {
  @extend .subtitle-font;

  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
  color: $body-color;
  text-align: left;
}

.img {
  object-fit: contain;
  width: 5.5rem;
  min-width: 5.5rem;
  height: 5.5rem;
  max-height: 5.5rem;
  border: 1px solid $base-colour-lightest;
  border-radius: $border-radius-small;
  padding: 0;
}

.tooltip {
  color: $warning;
}

.tooltip-text {
  max-width: 12rem;
}

@import '../../../common/assets/scss/variables';

.quick-shop-panel {
  padding: 1.25rem;
  background-color: #edf2f9;
  border: 4px solid $primary;
  border-radius: $border-radius-small;
  font-size: $body-font-size;
}

.title {
  font-size: 2rem;
  color: $primary;
  margin-bottom: 0.75rem;
}

.subtitle {
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.search-col {
  display: flex;
  align-items: center;
}

.search {
  width: 100%;
}

@import '../../../common/assets/scss/variables';

.notifications {
  margin-bottom: 2rem;
}

.change-alert {
  background-color: $white;
  border-radius: $border-radius-standard;
  padding: 1.25rem;
  cursor: pointer;

  :global(.btn-close) {
    padding: 1rem;
  }

  &:global(.alert-danger) {
    border-color: $danger;

    .heading {
      color: $danger;
    }
  }

  &:global(.alert-warning) {
    border-color: $warning;

    .heading {
      color: $warning;
    }
  }

  &:global(.alert-success) {
    border-color: $success;

    .heading {
      color: $success;
    }
  }
}

.heading {
  font-family: $be-vietnam-pro;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.75rem;

  svg {
    vertical-align: baseline;
  }
}

.icon {
  margin-right: 1.25rem;
}

.body {
  color: $body-color;
  font-size: $body-font-size;
  overflow-wrap: anywhere;

  p {
    margin-bottom: 0.875rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

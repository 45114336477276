@import '../../../common/assets/scss/variables';

.node-wrapper {
  padding-top: 0.125rem;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.node {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
  height: 2.5rem;
  border: 1px solid $border-colour;
  border-radius: $border-radius-small;
  font-size: $body-font-size;
  padding: 0 0.75rem;
  cursor: pointer;

  &:global(.selected) {
    border-color: $success;
  }

  &:global(.inactive) {
    .node-name {
      color: $subtle;
    }
  }
}

.node-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
}

.node-description {
  font-size: 0.75rem;
}

.node-icon {
  display: inline-block;
  min-width: 1rem;
  width: 1rem;
  margin-right: 0.75rem;
}

.dropdown-viewport {
  padding-block: 0.125rem;
}

.dropdown-content {
  min-width: 16rem;
}

@import 'variables';

.sidebar-nav {
  .items {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding-left: 0;
  }

  .item,
  .section-title {
    display: block;
    font-size: 1rem;
    text-align: left;
    padding: 0.75rem 2.25rem;
    border-bottom: 1px solid $base-colour-lightest;
  }

  .section-title,
  .section-title a {
    font-weight: 500;
    text-decoration: none;
    color: $menu-colour;
  }

  .section-title a {
    margin: 0 -0.5rem;
  }

  .item {
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    &:hover,
    &:active,
    &:focus {
      border-left-color: transparent;
      background-color: $hover-a;
      outline: none;
    }
  }

  .link {
    display: inline-block;
    text-decoration: none;
    color: $menu-colour;
    padding: 0 0.25rem;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    width: 100%;
    cursor: pointer;
  }

  .active_link {
    @extend .link;

    color: var(--bs-dropdown-link-color);
    border-left-color: $primary;
    outline: none;
  }

  .company-name {
    padding: 0 1rem;
    line-height: 2.5rem;
    text-align: right;
    background-color: $base-colour-lighter;
    position: sticky;
    top: 0;
  }
}

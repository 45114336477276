@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';

.tasks-filter {
  display: flex;
  flex-direction: row;
  padding: 1.75rem 0.5rem;
  padding-top: 0;
}

.input {
  @include ma-input();
}

@import '../../../common/assets/scss/variables';

.table-wrapper {
  border: 1px solid $border-colour;
  border-radius: $border-radius-small;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
}

.order-items-table {
  background-color: $white;
  margin: 0;

  :global {
    .heading {
      white-space: nowrap;
      font-size: $body-font-size;
      font-weight: 500;
      height: 1.28rem;
      margin: 0;
    }

    .reference,
    .part_number {
      width: 10%;
    }

    .description {
      width: 30%;
    }

    .quantity {
      width: 5%;
      text-align: right;
    }

    .estimated_delivery_date {
      width: 10%;
    }

    .supplier {
      width: 15%;
    }

    .issues {
      width: calc(20% - 1.5rem);
    }

    .actions {
      width: 1.5rem;
    }

    td:not(.actions) {
      cursor: pointer;
    }
  }

  thead tr {
    border-top: none;
  }

  th,
  td {
    vertical-align: middle;
    font-size: $body-font-size;
    text-align: left;
    border: none;
    user-select: none;
  }

  th {
    height: 3.5rem;
    padding: 0 0.75rem;
  }

  td {
    height: 3.25rem;
    padding: 0.25rem 0.75rem;
  }
}

$ma-controls-min-height: 2.5rem;

@mixin ma-input {
  border-color: $border-colour;
  border-radius: $border-radius-small;
  font-size: $body-font-size;
  line-height: normal;
  min-height: $ma-controls-min-height;

  &:active,
  &:hover,
  &:focus,
  &:focus-visible:active {
    outline: none;
    box-shadow: none;

    &:not(:disabled) {
      border-color: $primary-a;
      background-color: $white;
    }
  }

  &::placeholder {
    font-size: $body-font-size;
    color: $base-colour-lighter-grey;
  }
}

@mixin ma-search-input {
  @include ma-input();
}

@mixin ma-select {
  border-color: $border-colour;
  border-radius: $border-radius-small;
  font-size: $body-font-size;
  line-height: normal;
  min-height: $ma-controls-min-height;
  color: $body-color;

  &:active,
  &:hover,
  &:focus,
  &:focus-visible:active {
    outline: none;
    box-shadow: none;
    border-color: $primary-a;
  }
}

@mixin ma-button {
  color: $body-color;
  background-color: $white;
  border-color: $border-colour;
  border-radius: $border-radius-small;
  font-size: $body-font-size;
  line-height: normal;
  min-height: $ma-controls-min-height;

  &.active {
    background-color: $hover-a;
  }

  &:not(.active) {
    &:active,
    &:hover,
    &:focus,
    &:focus-visible:active {
      color: $body-color;
      background-color: $white;
      outline: none;
      box-shadow: none;
      border-color: $primary-a;
    }
  }

  &:disabled {
    border-color: $border-colour;
    background-color: $input-disabled-bg;
    opacity: 1;
  }
}

@import 'variables';

.warning {
  display: flex;
  align-items: center;
  background-color: rgba($warning, 0.25);
  border-radius: $border-radius-small;

  svg {
    width: 8%;
    color: $warning;
    margin-left: 1rem;
  }

  p {
    margin: 1rem;
    font-size: $body-font-size;
    font-weight: bold;
  }
}

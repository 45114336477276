@import 'variables';

.ma-tabs {
  .tabs {
    :global {
      .nav-item {
        background-color: $bg-colour;
        .active {
          background-color: $bg-colour;
          border-bottom: 0.5rem solid $primary-a;
          color: $primary-a;
        }
      }

      .nav-link {
        padding: 0.625rem 1.25rem 0.25rem;
        color: $body-color;
        border: none;

        .no-data {
          color: $subtle;
        }
      }
    }
  }
}

@import 'variables';

.ma-pie-chart {
  :global {
    .recharts-pie:focus {
      outline: none;
    }

    .recharts-pie-sector {
      cursor: pointer;
    }

    .recharts-label-list .recharts-label {
      font-size: $body-font-size;
      font-weight: 400;
      fill: $white;
      cursor: pointer;
      pointer-events: none;
    }

    .total-count {
      font-family: $plus-jakarta-sans;
      font-size: 1.5rem;
      fill: $primary-a;
      transform: translateY(-1rem);
    }

    .total-caption {
      @extend .total-count;

      transform: translateY(1rem);
    }
  }

  &.mobile {
    .legend {
      width: auto;
      padding: 0;
      padding-top: 1.5rem;
      max-width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-wrap: wrap;
      row-gap: 0.5rem;
    }

    .legend-item {
      margin-bottom: 0;
    }
  }
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  column-gap: 1rem;
  row-gap: 1rem;
  font-size: 0.875rem;
  padding-left: 2.5rem;
  margin: 0;
  width: 9rem;
}

.legend-item {
  display: inline-flex;
  margin: 0;
  font-size: 0.875rem;
  list-style: none;
  width: max-content;

  &:last-child {
    margin-bottom: 0;
  }
}

.legend-icon {
  display: inline-block;
  min-width: 1.25rem;
  height: 1.25rem;
  border-radius: $border-radius-small;
  margin-right: 0.75rem;
}

@import '../../../common/assets/scss/variables';

.modal {
  :global {
    .modal-body {
      font-size: $body-font-size;
    }

    .ma-select-trigger {
      max-width: 12rem;
    }
  }

  p {
    margin: 0;
  }

  form {
    padding: 1.25rem;
    padding-bottom: 0;
  }

  label {
    font-weight: 600;
  }

  .form-group {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

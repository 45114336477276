@import '../scss/variables';
@import '../scss/ma-controls';

.ma-confirm {
  :global(.modal-footer) {
    border-top: none;
  }

  &.question,
  &.warning {
    :global(.modal-content) {
      border: 1px solid $primary;
    }

    :global(.modal-body) {
      p {
        font-size: $body-font-size;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding: 1rem 1.25rem;
      color: $white;
      background-color: $primary;

      h4 {
        margin: 0;
        margin-left: 1.5rem;
        font-size: 1.25rem;
      }
    }
  }

  &.warning {
    :global(.modal-content) {
      border-color: $warning;
    }

    .header {
      background-color: $warning;
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    margin: 0 1rem;
    width: 12.5rem;
    min-width: fit-content;
    min-height: $ma-controls-min-height;
  }
}

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';

.custom-notes-tab {
  :global(.invalid-feedback) {
    display: block;
  }
}

.form-control {
  @include ma-input();

  &[name='internalPartNumber'] {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }

  &[name='note'] {
    min-height: 9.375rem;
  }
}

.status {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 0.5rem;
  min-height: 1.125rem;
  color: $body-color;

  p {
    margin: 0;
  }
}

@import 'variables';
@import 'ma-controls';

.ma-inline-edit-input {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.75rem;
  row-gap: 0.5rem;
  min-height: $ma-controls-min-height;

  :global(.spinner-border) {
    color: $subtle;
  }
}

.icon-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  min-width: 1rem;
  min-height: 1rem;
  align-items: center;

  :global(.btn) {
    min-width: inherit;
    min-height: inherit;

    &:focus {
      outline: 1px solid $primary;
    }

    &:active {
      outline: none;
    }

    &:disabled {
      color: $body-color;
      background-color: $white;
    }

    svg {
      vertical-align: top;
    }
  }
}

.confirm {
  &:global(.btn) {
    color: $success;

    &:hover,
    &:focus {
      color: $success;
      opacity: 0.8;
    }
  }
}

.cancel {
  &:global(.btn) {
    color: $danger;

    &:hover,
    &:focus {
      color: $danger;
      opacity: 0.8;
    }
  }
}

@import 'variables';
@import 'ma-controls';

.ma-add-quantity {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 0.75rem;

  .input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  :global {
    .form-label {
      margin: 0;
      font-size: $body-font-size;
    }

    .form-control {
      @include ma-input();
      width: 5rem;
      margin-left: 1rem;
    }

    .btn {
      flex-grow: 1;
      margin-top: 0.75rem;
      min-width: fit-content;
      min-height: $ma-controls-min-height;
    }
  }
}

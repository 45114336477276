@import 'variables';
@import 'ma-controls';
@import 'global-overrides';

body {
  background-color: $bg-colour;
}

header {
  margin-top: 0;
  background-color: $header-bg-colour;
  height: $main-header-height;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .navbar-brand {
    height: 2.5rem;
    margin: 0 0.5rem;

    svg {
      color: $header-colour;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

.content {
  max-width: 100%;
  height: calc(100dvh - $main-header-height - $bottom-toolbar-height);
  scrollbar-gutter: stable;
  overflow-y: auto;
  flex-grow: 1;

  @include media-breakpoint-up(md) {
    width: calc(100% - var(--left-sidebar-width));
    height: calc(100dvh - $main-header-height);
  }
}

.loading-screen {
  .header {
    font-size: 64px;
    color: $body-color;

    svg {
      vertical-align: middle;
      padding-bottom: 8px;
      margin-right: -2px;
      height: 100px;
      width: 100px;
    }
  }

  .error-message {
    font-size: 2em;
  }
}

h1 {
  color: $body-color;
  font-size: 1.8em;
}

h2 {
  color: $body-color;
  font-size: 1.4em;
}

.ma-page {
  padding: 1.5rem;
  padding-top: 1.85rem;
  container: ma-page / inline-size;

  .title {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    color: $primary;
    margin: 0;
  }
}

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';

.hazards-tab {
  display: flex;
  flex-direction: column;
  font-size: $body-font-size;

  hr {
    align-self: center;
    width: 50%;
  }

  &.mobile {
    padding: 0;
  }
}

.actions {
  display: flex;
  flex-direction: row-reverse;
}

.edit-btn {
  @include ma-button();
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.edit-form {
  :global(.invalid-feedback) {
    display: block;
  }
}

.hazards-and-restrictions {
  table {
    th {
      font-weight: bold;
    }

    td {
      padding-top: 1.2rem;
    }

    .status,
    .warning {
      white-space: break-spaces;
    }

    .status {
      width: 250px;
      min-width: 250px;

      &.mobile {
        width: 100px;
        min-width: 100px;
      }
    }
  }
}

.coshh-and-licence .content {
  gap: 0;
  padding: 0;
  margin-bottom: 1rem;

  p {
    margin-bottom: 0;
  }

  .coshh,
  .licence,
  .note {
    margin-bottom: 1.25rem;
    word-break: normal;
    overflow-wrap: anywhere;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .expired {
    color: $danger;
    font-size: 0.75rem;
  }
}

.edit-hazards {
  .tables {
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0.5rem 0 1rem 0;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    font-size: $body-font-size;
  }

  th,
  td {
    border-style: hidden;
  }

  th {
    position: sticky;
    top: 0;
    font-weight: normal;
  }

  td {
    vertical-align: middle;
  }

  .potential {
    td {
      border: solid 2px rgba($warning, 0.6);
      border-left: none;
      border-right: none;
    }

    td:first-child {
      border-left: solid 2px rgba($warning, 0.6);
      border-radius: $border-radius-small 0 0 $border-radius-small;
    }

    td:last-child {
      border-right: solid 2px rgba($warning, 0.6);
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }

  .checkbox-cell {
    width: 10px;
    min-width: 10px;
  }

  .status-cell {
    width: 180px;
    min-width: 180px;

    &.mobile {
      width: 100px;
      min-width: 100px;
    }
  }

  input {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: $border-radius-small;
    margin-top: 0;
  }

  .checkbox {
    :checked {
      background-color: $white;
    }

    :focus {
      box-shadow: none;
    }
  }
}

.confirmation {
  padding-top: 15rem;
}

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';

.filters-wrapper {
  border: 1px solid $border-colour;
  border-radius: $border-radius-small;
  overflow: hidden;
}

.filter-input-wrapper {
  background-color: $white;
  padding-top: 0.25rem;
  padding-bottom: 0.75rem;
  position: sticky;
  top: 0;
  z-index: 1;
}

.filter-input {
  @include ma-input();
  font-size: 0.75rem;
  padding: 0.125rem 0.5rem;
  min-height: 2rem;
}

.filter-list-wrapper {
  max-height: 18rem;
  overflow-y: auto;
}

.accordion {
  color: $body-color;
  background-color: $white;
  border: none;

  &:not(:last-child) {
    border-bottom: 1px solid $border-colour;
  }

  :global {
    .accordion-header {
      font-family: $be-vietnam-pro;
    }

    .accordion-body,
    .accordion-button {
      padding: 0.75rem;
      font-size: $body-font-size;
      box-shadow: none;
      background-color: $white;
    }

    .accordion-body {
      padding-top: 0;
    }

    .accordion-button:not(.collapsed) {
      color: $body-color;
    }
  }
}

.entry {
  font-size: 0.75rem;
  display: flex;
  align-items: flex-end;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  :global {
    .form-check-input {
      min-width: 1.25rem;
      height: 1.25rem;
      border-color: $border-colour;
      border-radius: $border-radius-small;
      background-color: transparent;
      box-shadow: none;
      margin-right: 0.75rem;
    }

    .form-check-label {
      line-height: initial;
    }
  }
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: $bg-colour;
  z-index: 10;

  &.mobile {
    top: -1.5rem;
    padding-top: 1.5rem;
    margin-top: -1.5rem;
    background-color: $white;
  }

  :global(.btn) {
    height: $ma-controls-min-height;
    font-size: $body-font-size;
    line-height: normal;
    flex-grow: 1;
  }
}

@import '../../../../node_modules/bootstrap/scss/functions';

// Font Variables
$be-vietnam-pro: 'Be Vietnam Pro', sans-serif;
$plus-jakarta-sans: 'Plus Jakarta Sans', sans-serif;

// Primary Colours
$white: #fff;
$black: #000;
$primary-colour-blue: #030dff;
$primary-colour-light-blue: #2278b6;
$primary-colour-dark-purple: #043659;

// Secondary Colours
$secondary-colour-green: #3eaf3f;
$secondary-colour-red: #cf012e;
$secondary-colour-orange: #f98f2e;
$secondary-colour-light-blue: #cddff6;
$secondary-colour-light-purple: #d6d6fa;

// Primary Tint Colours
$primary-colour-light: #f1f0f3;
$primary-colour-lightest: #f7f7f7;

// Base Colours
$base-colour-darkest: #1d1d1d;
$base-colour-dark: #444;
$base-colour-light: #cdcdcd;
$base-colour-lighter: #d9d9d9;
$base-colour-lightest: #ececec;
$base-colour-lighter-grey: #adadad;
$base-colour-dark-grey: #9b9b9b;
$base-colour-gold: #fed66d;

// Primary-A Colours
$primary-a: $primary-colour-light-blue;
$hover-a: $secondary-colour-light-blue;
$tint-a: $primary-colour-lightest;

// Primary-B Colours
$primary-b: $primary-colour-dark-purple;
$hover-b: $secondary-colour-light-purple;
$tint-b: $primary-colour-light;

// Core Colours
$header-colour: $base-colour-darkest;
$menu-colour: $base-colour-darkest;
$header-bg-colour: $white;
$bg-colour: $tint-a;
$border-colour: $base-colour-light;
$subtle: $base-colour-dark-grey;

// Border radii
$border-radius-small: 0.25rem;
$border-radius-standard: 0.625rem;

// Bootstrap Overrides
$body-color: $base-colour-dark;
$border-color: $border-colour;
$primary: $primary-a;
$secondary: $hover-a;
$dark: $base-colour-dark;
$action: $secondary-colour-green;
$success: $secondary-colour-green;
$info: $secondary-colour-light-blue;
$danger: $secondary-colour-red;
$warning: $secondary-colour-orange;
$link-color: $primary-colour-blue;
$form-check-input-checked-color: $body-color;
$carousel-indicator-width: 1rem;
$carousel-indicator-height: 1rem;
$table-color: $body-color;
$input-disabled-bg: $base-colour-lightest;
$border-radius: $border-radius-small;
$input-focus-border-color: $primary-a;

$headings-font-family: $plus-jakarta-sans;
$font-family-base: $be-vietnam-pro;

$enable-rounded: false;
$enable-rfs: false;

// Main Layout
$main-header-height: 3.5rem;
$bottom-toolbar-height: 3rem;
$quick-access-header-height: 5.125rem;
$quick-access-controls-height: 4.25rem;
$body-font-size: 0.875rem;

@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

@import '../../../common/assets/scss/variables';

.badge {
  font-weight: 400;
  font-size: $body-font-size;
  padding: 0.25rem 1.25rem;

  &:global {
    &.text-new,
    &.text-pending,
    &.text-cancelled {
      color: $dark;
    }

    &.text-withdrawn,
    &.text-rejected,
    &.text-approved,
    &.text-complete {
      color: $white;
    }

    &.bg-new {
      background-color: $secondary-colour-light-blue;
    }
    &.bg-pending {
      background-color: $secondary-colour-light-purple;
    }
    &.bg-withdrawn {
      background-color: $secondary-colour-orange;
    }
    &.bg-approved {
      background-color: $secondary-colour-green;
    }
    &.bg-rejected {
      background-color: $secondary-colour-red;
    }
    &.bg-complete {
      background-color: $primary-colour-light-blue;
    }
    &.bg-cancelled {
      background-color: $base-colour-light;
    }
  }
}

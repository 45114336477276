@import '../../../common/assets/scss/variables';

.price {
  font-size: 1rem;
  font-weight: 400;
  text-align: right;
  text-wrap: nowrap;
}

.list-price {
  color: $subtle;
  font-size: $body-font-size;
}

.price-discount {
  color: $danger;
  padding-left: 5pt;
  font-size: $body-font-size;
}

.discount-info {
  white-space: nowrap;
  text-align: end;
}

.product-price.full {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 2.625rem;

  .price {
    font-size: 1.5rem;
  }

  .discount-info {
    display: table;
  }

  .list-price,
  .price-discount {
    display: table-row;
    text-align: start;
  }

  .label {
    display: table-cell;
    padding-right: 0.5rem;
    color: $body-color;
  }

  .list-price {
    color: $body-color;

    s {
      text-decoration: none;
    }
  }

  .price-discount {
    padding-left: 0;
  }
}

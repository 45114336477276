@import 'variables';

.ma-accordion {
  :global {
    .accordion-item {
      border-left: none;
      border-right: none;
    }

    .accordion-header {
      border-bottom: 0.1rem solid $primary-colour-light;
    }

    .accordion-button {
      color: $menu-colour;
      background-color: $white;
      box-shadow: inset 0.5rem 0 0 0 $primary-a;

      &.collapsed {
        box-shadow: none;
      }

      .no-data {
        color: $subtle;
      }
    }
  }
}

@import '../../../common/assets/scss/variables';

.grouped-view {
  section {
    padding-block: 0.75rem;
    border-top: 1px solid $border-colour;

    h5 {
      font-family: $be-vietnam-pro;
      font-size: $body-font-size;
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  &.compact {
    section {
      padding-block: 1.25rem;
    }
  }
}

.section-header-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 1rem;
}

.section-header {
  flex-grow: 1;
  cursor: pointer;

  .supplier-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 0.75rem;
  }

  .supplier-status {
    margin: 0;
  }

  .header-controls {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
  }

  .header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.compact-summary {
      justify-content: flex-end;
      margin-top: 1.25rem;
    }
  }
}

.supplier-summary-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 4rem;

  &:global(.compact) {
    padding-right: 0;
  }
}

.total-spend-row {
  @extend .supplier-summary-row;

  justify-content: space-between;

  &:global(.compact) {
    flex-direction: column;

    .spend-summary-entries {
      align-self: flex-end;
      margin-top: 1.25rem;
    }
  }
}

.spend-summary-entries {
  p {
    margin-bottom: 0;
    display: table-row;
    overflow-wrap: break-word;

    &:not(:last-child) {
      span,
      strong {
        padding-bottom: 0.25rem;
      }
    }

    span,
    strong {
      display: table-cell;

      &:first-child {
        display: flex;
        justify-content: flex-end;
        color: $subtle;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
        margin-right: 0.5rem;
        padding: 0;
      }

      &:nth-child(2) {
        min-width: 5rem;
        text-align: right;
      }

      .warning-icon {
        font-size: 1rem;
        color: $warning;
      }

      .danger-icon {
        font-size: 1rem;
        color: $danger;
      }

      .tooltip {
        color: $body-color;
        padding: 0;
        margin: 0;
      }
    }
  }
}

@import '../../../common/assets/scss/variables';

.supplier-status-indicator-icon {
  display: inline-flex;
  margin-left: 0.5rem;

  .icon {
    &:hover {
      cursor: pointer;
    }
  }

  .can-buy {
    color: $action;
  }

  .could-buy {
    color: $warning;
  }
}

@import '../../../common/assets/scss/variables';

.applied-filters {
  background-color: $white;
  border-bottom: 1px solid $border-colour;
}

.filter-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.header {
  @extend .filter-item;

  padding: 0.75rem;
  font-size: $body-font-size;
  margin-bottom: 0;
}

.list {
  margin: 0;
  padding: 0.75rem;
  padding-top: 0;
}

.remove-btn {
  cursor: pointer;
}

.clear-all {
  @extend .remove-btn;

  color: $link-color;
}

@import '../../../common/assets/scss/SearchPage.module.scss';

.sorting {
  min-width: 13rem;
}

.summary {
  min-height: $body-font-size;
  font-size: $body-font-size;
  overflow-wrap: anywhere;
}

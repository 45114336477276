@import 'variables';

.ma-product-carousel {
  margin: 0 1.25rem;
  padding-bottom: 2.5rem;

  &.mobile {
    margin: 0 -1.25rem;
  }

  :global .carousel {
    .carousel-indicators {
      margin: 0 auto;
      bottom: -2.5rem;

      button {
        border-radius: 50%;
        border: 1px solid $primary-a;
        margin: 0 0.25rem;
        opacity: 1;

        &.active {
          background-color: $primary-a;
        }
      }
    }
  }
}

.slide-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';

.form {
  height: $ma-controls-min-height;

  &.active,
  &:hover,
  &:active,
  &:focus,
  &:focus-visible,
  &:focus-within {
    .query-btn,
    .query-input,
    .type {
      border-color: $primary-a;
    }
  }
}

.query-input {
  @include ma-search-input();
  border-radius: 0;

  &:first-child {
    border-top-left-radius: $border-radius-small;
    border-bottom-left-radius: $border-radius-small;
  }
}

.query-btn {
  @include ma-button();
  color: $body-color;
  background-color: $white;
  height: $ma-controls-min-height;
  aspect-ratio: 1 / 1;
  padding: 0;
  border-radius: $border-radius-small;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid $border-colour;
  margin-left: -1px;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    background-color: $white;
    color: $body-color;
    border-color: $border-colour;
  }

  &:disabled {
    border-color: $danger;
  }

  &:global(.btn:active) {
    background-color: $hover-a;
  }

  &:global(.btn:focus-visible),
  &:global(.btn:active:focus-visible) {
    box-shadow: none;
  }
}

.type {
  @include ma-select();
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-width: 9.5rem;
  max-width: max-content;
}

@import 'variables';

.ma-icon-btn {
  position: relative;

  :global(.badge) {
    font-weight: 400;
    font-size: 0.6rem;
    position: absolute;
    top: 20%;
    right: 6%;
  }

  &:global(.btn) {
    background-color: transparent;
    color: $header-colour;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    aspect-ratio: 1 / 1;
    border: none;

    &::after {
      content: none;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      color: $header-colour;
      background-color: transparent;
      border-top-color: transparent;
      box-shadow: none;
    }

    &:global(.primary) {
      &:hover {
        background-color: $hover-a;
      }
    }

    &:global(.secondary) {
      color: $body-color;

      &:hover {
        background-color: $hover-b;
      }
    }

    &:active:not(.active) {
      border-color: transparent;
    }

    &:global(.active-bar-top),
    &:global(.active-bar-bottom) {
      border: none;
      border-bottom: 0.5rem solid transparent;
      border-top: 0.5rem solid transparent;
    }

    &:global(.active-bar-left),
    &:global(.active-bar-right) {
      border: none;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
    }

    &:global(.primary.active.active-bar-top) {
      border-top-color: $primary-a;

      &:active {
        border-top-color: $primary-a;
      }
    }

    &:global(.primary.active.active-bar-bottom) {
      border-bottom-color: $primary-a;

      &:active {
        border-bottom-color: $primary-a;
      }
    }

    &:global(.primary.active.active-bar-left) {
      border-left-color: $primary-a;

      &:active {
        border-left-color: $primary-a;
      }
    }

    &:global(.primary.active.active-bar-right) {
      border-right-color: $primary-a;

      &:active {
        border-right-color: $primary-a;
      }
    }

    &:global(.secondary.active.active-bar-top) {
      border-top-color: $primary-b;

      &:active {
        border-top-color: $primary-b;
      }
    }

    &:global(.secondary.active.active-bar-bottom) {
      border-bottom-color: $primary-b;

      &:active {
        border-bottom-color: $primary-b;
      }
    }

    &:global(.secondary.active.active-bar-left) {
      border-left-color: $primary-b;

      &:active {
        border-left-color: $primary-b;
      }
    }

    &:global(.secondary.active.active-bar-right) {
      border-right-color: $primary-b;

      &:active {
        border-right-color: $primary-b;
      }
    }
  }
}

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.form {
  font-size: $body-font-size;

  h4 {
    @extend .subtitle-font;

    margin-bottom: 0.75rem;
  }

  th {
    color: $subtle;
    font-weight: normal;
    text-align: end;
    white-space: nowrap;
    vertical-align: top;
  }

  td {
    padding-left: 1rem;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.summary {
  @extend .row;

  margin-bottom: 1.75rem;
}

.quantity {
  @extend .row;

  width: 50%;
  row-gap: 0.25rem;
  flex-wrap: wrap;

  .label {
    color: $subtle;
  }

  input {
    max-width: 5rem;
  }

  td {
    min-width: 6rem;
    text-align: right;
  }
}

.form.compact {
  .summary {
    flex-direction: column;
    margin-bottom: 1rem;
    row-gap: 1rem;
  }

  .quantity {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}

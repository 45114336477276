@import 'variables';

.account-menu {
  &[data-bs-popper] {
    margin-top: 0;
    padding: 0 1rem 1rem 1rem;
    font-size: $body-font-size;
  }

  :global {
    .dropdown-item {
      color: $header-colour;
      box-sizing: border-box;
      padding: 0.25rem 0.25rem;
      border-left: 0.25rem solid transparent;
      border-right: 0.25rem solid transparent;

      &:active,
      &:hover,
      &:focus,
      &:focus-visible {
        border-left-color: transparent;
        background-color: $hover-a;
        outline: none;
      }
    }
  }
}

.company-name {
  text-align: center;
  white-space: nowrap;
  margin-left: -1rem;
  margin-right: -1rem;
  color: $header-colour;
  background-color: $base-colour-lighter;
}

.username {
  color: $header-colour;
  white-space: nowrap;
  line-height: 2rem;
  vertical-align: middle;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 1.5rem 0;
}

.account-id {
  font-size: 0.5rem;
  vertical-align: middle;
}

@import '../../../common/assets/scss/variables';

.spend-category-fields {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0;

  &.compact {
    display: flex;
    flex-direction: column;
  }
}

.spend-category {
  &.compact {
    width: 100%;
    margin-bottom: 1rem;
  }

  .name {
    color: $subtle;
    font-size: $body-font-size;
  }
}

@import '../../assets/scss/variables';
@import '../../assets/scss/ma-controls';

.ma-action-icon {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  min-width: 2.5rem;
  min-height: 2.5rem;
  aspect-ratio: 1 / 1;
  color: $menu-colour;

  &:global(.btn) {
    &:hover,
    &:active,
    &:focus,
    &:disabled,
    &:focus-visible,
    &:focus-visible:active {
      color: inherit;
      background-color: transparent;
      outline: none;
      box-shadow: none;
    }
  }
}

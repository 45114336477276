@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';

.favourites {
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  height: calc(100% - $quick-access-controls-height);
  overflow-y: auto;
}

.controls {
  padding: 1.75rem 0.5rem;
  padding-top: 0;
}

.input {
  @include ma-input();
}

@import '../../../common/assets/scss/variables';

.toggle-btn-group {
  height: 2rem;
  min-width: max-content;
  white-space: nowrap;
  border-radius: $border-radius-small;
  background-color: $base-colour-lighter;
}

.toggle-btn {
  color: $subtle;
  background-color: transparent;
  border: solid 1px transparent;
  border-radius: $border-radius-small;
  height: 2rem;
  padding: 0.1875rem;
  aspect-ratio: 1 / 1;

  > svg {
    margin-bottom: 0.125rem;
  }

  &:hover,
  &:focus,
  &:active {
    color: $subtle;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &:focus-visible {
    box-shadow: none;
    outline: auto;
  }

  &:disabled {
    --bs-btn-disabled-opacity: 1;
    color: $menu-colour;
    background-color: $white;
    border-color: $border-colour;
    pointer-events: none;
    cursor: default;
  }
}

@import '../scss/variables';

.ma-vertical-tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tab-list {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  min-width: 15rem;
  background-color: $tint-a;
  border-right: 1px solid $border-colour;

  :global {
    .nav-item {
      border-left: 0.5rem solid transparent;
      transition: border-color 0.15s ease-in-out;
    }

    .nav-item.active {
      border-left-color: $primary-a;
    }

    .nav-link {
      padding: 0.625rem 0.25rem;
      color: $body-color;
      margin-right: -1px;
      border: 1px solid transparent;
      border-left: none;

      &:focus,
      &:focus-visible {
        outline: none;
        box-shadow: none;
      }
    }

    .nav-link.active {
      color: $body-color;
      background-color: $white;
      border-top-color: $border-colour;
      border-bottom-color: $border-colour;
    }
  }
}

.no-data {
  color: $subtle;
}

.tab-content {
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex-grow: 1;
  min-height: 20rem;
  padding-left: 1.25rem;
}

@import 'variables';
@import 'ma-controls';

.ma-switch-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  label {
    font-size: $body-font-size;
    color: $body-color;
    padding-right: 0.75rem;
  }
}

.ma-switch {
  position: relative;
  padding: 0;
  width: 2.5rem;
  height: 1.25rem;
  border: none;
  border-radius: 9999px;
  background-color: $base-colour-dark-grey;
}

.ma-switch:focus {
  outline: 1px solid $primary;
}

.ma-switch[data-state='checked'] {
  background-color: $success;
}

.ma-switch-thumb {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background-color: $white;
  border-radius: 9999px;
  border: 1px solid $base-colour-light;
  transition: transform 100ms;
  will-change: transform;
}

.ma-switch-thumb[data-state='checked'] {
  transform: translateX(1.25rem);
}

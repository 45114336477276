@import '../../../common/assets/scss/variables';

.spend-category-history-table {
  border-radius: $border-radius-small;
  overflow: hidden;

  table {
    margin: 0;
    background-color: $white;
    border-color: $base-colour-light;
    vertical-align: middle;
  }

  thead {
    height: 2.5rem;

    tr {
      border-top: none;
    }

    th {
      background-color: $secondary-colour-light-blue;
      color: $base-colour-dark;
      white-space: nowrap;
      border: none;
      font-weight: normal;

      &.date {
        padding-left: 1rem;
      }
    }
  }

  tbody {
    tr {
      height: 2.5rem;
    }

    td {
      border: none;

      &.date {
        padding-left: 1.25rem;
      }

      &.date,
      &.name {
        text-wrap: nowrap;
      }

      &.details {
        overflow-wrap: anywhere;
      }
    }
  }
}

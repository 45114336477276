@import 'ma-controls';

.button {
  margin-left: 1em;
  min-width: fit-content;

  span {
    margin-left: 0.5em;
  }
}

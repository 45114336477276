.error-container {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  :global {
    h1 {
      font-weight: bold;
      margin-bottom: 4rem;
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }
  }
}

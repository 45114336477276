@import 'variables';
@import 'ma-controls';

.ma-date-picker {
  button {
    @include ma-button();

    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;

    svg {
      min-width: fit-content;
      margin-right: 0.75rem;
    }

    &:global(.is-invalid) {
      border-color: $danger;

      &:active,
      &:hover,
      &:focus,
      &:focus-visible:active {
        border-color: $danger;
      }
    }
  }

  .date-range-unselected {
    color: $subtle;
  }
}

.popover {
  max-width: 100%;
  border-radius: 0.75rem;
}

.popover-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 0.75rem 0;
}

.clear-button {
  border-radius: $border-radius-small;
}

.selected {
  background-color: $primary;
  border-radius: 0.75rem;
}

.selected-single {
  background-color: $primary;
}

.range-middle {
  background-color: $secondary;
  border-radius: 0;
}

@import '../../../common/assets/scss/variables';

.info-card-wrapper {
  margin-top: 2rem;
}

.info-card {
  background-color: #edf2f9;
  border: 1px solid $primary-a;
  border-radius: $border-radius-small;
  padding: 1.25rem;
  font-size: 0.875rem;
  height: 100%;

  :global {
    .card-header {
      background: inherit;
      border: none;
      padding: 0;

      h4 {
        color: $primary;
      }
    }

    .card-body {
      padding: 0;
    }

    .card-footer {
      background: inherit;
      border: none;
      padding: 0;

      a {
        display: block;
        border-radius: $border-radius-small;
        min-width: fit-content;
        flex-grow: 1;
        margin-top: 0.75rem;
      }
    }
  }
}

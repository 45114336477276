@import 'variables';

.header-main-nav {
  flex-grow: 1;

  .dropdown-menu-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 0.5rem;
    min-width: min-content;
    width: max-content;
  }

  :global {
    .dropdown.nav-item {
      height: $main-header-height;
    }

    .dropdown-menu {
      min-width: min-content;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }

    .dropdown-toggle.nav-link {
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      color: $header-colour;
      font-size: 1rem;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      border-bottom: 0.5rem solid transparent;
      border-top: 0.5rem solid transparent;

      @include media-breakpoint-up(lg) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
      }

      &:global(.show) {
        background-color: $hover-a;
        box-shadow: none;
        outline: none;
      }

      &:global(.active) {
        border-bottom-color: $primary-a;
      }

      &:hover,
      &:focus,
      &:focus-visible {
        box-shadow: none;
        background-color: $hover-a;
      }

      &::after {
        content: none;
      }
    }

    .dropdown.show .dropdown-menu {
      margin-top: 0;
      box-shadow: 0px 12px 24px 1px rgba(0, 0, 0, 0.2);
    }

    .dropdown-item {
      color: $header-colour;
      display: inline-block;
      border-left: 0.25rem solid transparent;
      border-right: 0.25rem solid transparent;
      padding: 0.25rem 0.25rem;
      font-size: $body-font-size;
      margin: 0.125rem 0;
      line-height: 1rem;

      &:active,
      &:hover,
      &:focus,
      &:focus-visible {
        border-left-color: transparent;
        background-color: $hover-a;
        outline: none;
      }
    }

    .dropdown-item.active {
      border-left-color: $primary-a;
      background: transparent;
      color: $header-colour;
    }
  }
}

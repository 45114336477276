@import '../../../common/assets/scss/ma-controls';

.notifications-page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100dvh - $main-header-height - $bottom-toolbar-height);
  overflow: hidden;

  @include media-breakpoint-up(md) {
    height: calc(100dvh - $main-header-height);
  }

  &__title {
    padding-bottom: 1rem;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    gap: 1rem;
    flex-grow: 1;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &__filter {
    width: 100%;
    padding-bottom: 1rem;
    display: flex;
    gap: 1rem;
    flex-direction: row;
    justify-content: space-between;

    .btn {
      @include ma-button();
      flex-grow: 1;
      color: $body-color;
      font-size: $body-font-size;
      align-items: center;
    }

    @include media-breakpoint-up(md) {
      width: calc(2 / 12 * 100%);
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .btn {
        width: 100%;
        flex-grow: 0;
      }
    }
  }

  &__content {
    width: 100%;
    overflow: auto;
    padding: 1rem 0.25rem;

    @include media-breakpoint-up(md) {
      width: calc(10 / 12 * 100%);
      padding: 0.25rem;
    }
  }

  &__footer {
    width: 100%;
    padding: 1rem 0;

    .btn {
      @include ma-button();
      color: $body-color;
      font-size: $body-font-size;
    }

    @include media-breakpoint-up(md) {
      .btn {
        margin: 0 calc(var(--bs-gutter-x) * 0.5);
      }
    }
  }
}

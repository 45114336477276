@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.favourite-card {
  margin-bottom: 1.25rem;
  border: 1px solid $border-colour;
  border-radius: $border-radius-standard;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: 400;
    word-break: normal;
    overflow-wrap: anywhere;
    padding-bottom: 0.75rem;

    > a {
      @extend .primary-link;
    }
  }

  .body {
    @extend .body-font;
  }

  .footer {
    @extend .label-font;

    color: $body-color;
    padding-top: 0.75rem;
  }
}

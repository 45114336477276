@use 'sass:map';

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.basket {
}

.content {
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid $border-colour;
}

.empty-basket-message {
  margin-top: 2rem;
  margin-left: 10%;
  font-size: $body-font-size;

  h5 {
    font-family: $be-vietnam-pro;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }
}

.items {
  display: flex;
  flex-direction: row;
  column-gap: 1.75rem;
}

.item-groups {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 2rem;
}

.supplier-heading {
  display: flex;
  flex-direction: row;
  column-gap: 1.25rem;
  align-items: center;
  margin-bottom: 0.75rem;
  min-height: 1.375rem;

  h5 {
    font-size: 1.125rem;
    margin-bottom: 0;
  }
}

.supplier-check {
  display: flex;
}

.cards {
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
}

.summary {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 19.125rem;
  min-width: 19.125rem;
}

.basket-action-menu {
  margin-bottom: 0.625rem;
}

.order-request-row {
  :global(.invalid-feedback) {
    display: block;
  }

  .confidential {
    user-select: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: 1.25rem;
  }
}

.summary-breakdown {
  margin-top: 1rem;

  h5 {
    @extend .subtitle-font;

    color: $primary-a;
    margin-bottom: 0.75rem;

    span {
      color: $body-color;
    }
  }
}

.summary-breakdown-panel {
  padding: 0.5rem 0.75rem;

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}

.summary-breakdown-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.75rem 0;

  h6 {
    font-family: $be-vietnam-pro;
    font-weight: 700;
    font-size: $body-font-size;
    margin-bottom: 0.5rem;
  }

  p {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: $body-font-size;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    span,
    strong {
      &:first-child {
        color: $subtle;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    column-gap: 1rem;

    h6 {
      margin-bottom: 0;
    }

    p {
      column-gap: 5rem;
      min-width: 20rem;
    }
  }
}

.summary-breakdown-total {
  @extend .summary-breakdown-section;

  border-top: 1px solid $border-colour;
}

@container ma-page (width < #{map.get($grid-breakpoints, "md")}) {
  .items {
    flex-direction: column-reverse;
  }

  .summary {
    width: auto;
    min-width: auto;
  }

  .empty-basket-message {
    margin: 0 auto;
    margin-top: 2rem;
    text-align: center;
  }
}

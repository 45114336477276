@import 'variables';

.ma-card {
  background-color: $white;
  border-color: $border-colour;
  border-radius: $border-radius-standard;
  font-size: $body-font-size;
  color: $body-color;

  :global {
    .card-header {
      font-size: $body-font-size;
      padding: 1.25rem;
      padding-bottom: 0.5rem;
      background-color: initial;
      border: none;
    }

    .card-body {
      font-size: inherit;
      padding: 0 1.25rem;
      background-color: initial;
      border: none;

      &:last-child {
        padding-bottom: 1.25rem;
      }
    }

    .card-footer {
      font-size: inherit;
      padding: 1.25rem;
      padding-top: 0.5rem;
      background-color: initial;
      border: none;
    }
  }
}

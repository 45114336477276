@import '../../../common/assets/scss/variables';

.content {
  margin-top: 1.25rem;
  font-size: $body-font-size;
}

.panel {
  padding: 1rem;
}

.header {
  font-family: $plus-jakarta-sans;
  font-size: 1.125rem;
  padding-bottom: 1rem;
}

.view {
  padding-top: 1.25rem;
  border-top: 1px solid $border-colour;
}

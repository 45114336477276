@import '../../../common/assets/scss/variables';

.modal {
  :global {
    .modal-body {
      display: flex;
      flex-direction: column;
    }
  }
}

@use 'sass:map';

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';

.search-form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1.75rem;
  gap: 1rem;
}

.search-input {
  width: 50%;
}

.search-options {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  button {
    min-width: 16rem;
  }
}

@container ma-page (width < #{map.get($grid-breakpoints, "md")}) {
  .search-form-row {
    flex-direction: column;
  }

  .search-input {
    width: 100%;
  }

  .search-options {
    justify-content: space-between;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
}

.search-results {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  border-top: 1px solid $border-colour;
}

.sorting {
  display: flex;
  float: right;
  min-width: 9rem;

  &.product-search-sort {
    min-width: 14rem;
  }

  .results-pre-sort {
    margin-top: 1.6rem;
  }

  &.mobile {
    float: none;
    gap: 1rem;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
}

.card-grid {
  min-width: 0;
  flex-grow: 1;
  padding-top: 1.5rem;
}

.facets {
  min-width: 16rem;
  max-width: 16rem;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-top: 1.5rem;
  background-color: $white;
  border: 1px solid $border-colour;
  border-radius: $border-radius-small;
}

.offcanvas-filters-toggle-btn {
  @include ma-button();
  width: 100%;
}

.offcanvas-filters {
  &:global(.offcanvas-start.show),
  &:global(.offcanvas-start.offcanvas-toggling) {
    top: $main-header-height;
    height: calc(100dvh - $main-header-height - $bottom-toolbar-height);
    width: 100%;
    border: none;
  }
}

.offcanvas-filters-header {
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0;

  :global(.btn) {
    margin-right: -0.5rem;
  }
}

.offcanvas-filters-title {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.25rem;
  color: $primary-a;
  margin: 0;
}

.mobile {
  .search-form-row {
    margin-bottom: 0;
  }

  .search-input {
    min-width: 100%;
  }

  .search-results {
    border-top: none;
  }

  .search-controls {
    padding-top: 1.75rem;
    margin-bottom: 1.75rem;
    border-top: 1px solid $border-colour;
  }

  .card-grid {
    padding-top: 0;
  }
}

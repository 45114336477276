@import 'variables';

.ma-tooltip {
  position: relative;
  display: inline-block;
  padding: 0 0.25rem;
  cursor: pointer;
}

.text {
  min-width: 10rem;
  width: fit-content;
  padding: 0.5rem 0.75rem;
  background-color: $white;
  border: 1px solid $base-colour-lighter;
  border-radius: $border-radius-small;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: $body-color;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  white-space: normal;
  word-wrap: initial;
  z-index: 10;
  position: fixed !important;
}

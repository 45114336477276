@import 'variables';

.profile-avatar {
  background-color: $base-colour-lighter;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
}

@import '../../../common/assets/scss/variables';

.spend-category-history-list {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $border-color;
  }

  dt {
    text-align: start;
    margin-right: 2rem;
  }
}

@import '../scss/variables';
@import '../scss/typography';

.ma-description-list {
  @extend .body-font;

  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 0.5rem;
  row-gap: 0.25rem;
  margin: 0;
}

.term {
  font-weight: 400;
  text-align: end;
}

.details {
  margin: 0;
  white-space: pre-wrap;
  word-break: normal;
  overflow-wrap: anywhere;
}

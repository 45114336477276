@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';
@import '../../../common/assets/scss/ma-controls';

.receive-line-items {
  :global(.modal-content) {
    container: modal-content / inline-size;
  }

  :global(.modal-body) {
    padding-top: 0.75rem;
    padding-bottom: 0;
  }
}

.title {
  font-family: $be-vietnam-pro;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0;
}

.line-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $border-colour;
  padding-bottom: 1.25rem;
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0.5rem;
  }

  &.single {
    border-bottom: none;
    margin-bottom: 0rem;
  }
}

.description {
  @extend .title;

  margin-bottom: 0.75rem;
  overflow-wrap: anywhere;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  section {
    width: auto;
    overflow-wrap: anywhere;
  }
}

.order-header {
  display: flex;
  padding-bottom: 1.25rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid $border-colour;
  flex-direction: column;
}

.info,
.order-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  margin-bottom: 0.75rem;
}

.order-info {
  margin-bottom: 0;

  .supplier {
    margin-bottom: 0;
  }

  .ordered-by {
    font-size: $body-font-size;
    margin: 0.75rem 0;
  }
}

.table {
  font-size: $body-font-size;
  overflow: visible;
  margin-bottom: 0;

  th,
  td {
    border: none;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: normal;
  }

  th {
    padding-right: 0.5rem;
    text-align: right;
    color: $subtle;
    min-width: 6.25rem;
  }

  td {
    min-width: fit-content;
    width: 100%;
    max-width: 0;
  }
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1.25rem;
  justify-content: space-between;
}

.control {
  display: flex;
  flex-direction: column;

  label {
    font-size: $body-font-size;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  input,
  textarea {
    @include ma-input;
  }
}

.clear-all {
  min-height: $ma-controls-min-height;
}

.quantity {
  min-width: 6rem;
}

.date {
  flex-grow: 1;
  min-width: max-content;
  max-width: none;
}

.toggle-edit {
  @extend .primary-link;

  cursor: pointer;
}

.toggle-unreceive {
  flex-grow: 1;

  button {
    font-size: 1rem;
    width: 100%;
    min-height: $ma-controls-min-height;
  }
}

.comment {
  padding-bottom: 1.25rem;
  border-bottom: 1px solid $border-colour;
  margin-bottom: 0.5rem;
}

@container modal-content (width >= 36rem) {
  .order-header {
    flex-direction: row;

    .content {
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  .order-info {
    max-width: 26rem;
  }

  .order-info .ordered-by {
    margin-bottom: 0;
  }

  .content {
    flex-direction: row;
    align-items: flex-end;

    .controls {
      width: 20.25rem;
    }
  }

  .info {
    flex-direction: row;
    margin-bottom: 0;
  }

  .date {
    max-width: 13rem;
  }

  .toggle-unreceive {
    min-height: 4.0625rem;
    justify-content: flex-end;
  }
}

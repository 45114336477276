@import 'variables';

.user-toolbar {
  display: flex;
  flex-direction: row;
  margin: 0 0.5rem;
  height: $main-header-height;

  :global {
    .dropdown.active .btn {
      border-bottom-color: $primary-a;
    }

    .dropdown.show .dropdown-menu {
      box-shadow: 0 12px 24px 1px rgba(0, 0, 0, 0.2);
    }
  }

  .nav-link {
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;

    &:hover,
    &:active,
    &:focus {
      color: var(--bs-dropdown-link-color);
      border-left-color: $primary-a;
      background-color: $bg-colour;
      outline: none;
    }
  }
}

.offcanvas-menu {
  &:global(.offcanvas-end.show),
  &:global(.offcanvas-end.offcanvas-toggling) {
    top: $main-header-height;
    height: calc(100dvh - $main-header-height - $bottom-toolbar-height);
    width: 100%;
  }
}

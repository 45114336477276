@import 'variables';
@import 'ma-controls';

.ma-select-trigger {
  @include ma-select;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.375rem 0.75rem;
  background-color: $white;
  border: 1px solid $border-colour;
  border-radius: $border-radius-small;
  max-width: var(--radix-select-trigger-width);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:disabled {
    border-color: $border-colour;
    color: $subtle;
  }

  &[data-placeholder] {
    color: $subtle;

    .ma-select-value {
      color: $body-color;
    }

    &:disabled {
      .ma-select-value {
        color: $subtle;
      }
    }
  }

  &[data-state='open'] {
    border-color: $primary-a;
  }

  &.is-invalid {
    border-color: $danger;
  }
}

.ma-select-icon {
  color: $body-color;
  margin-left: 0.5rem;
}

.ma-select-content {
  z-index: 1100;
  overflow: hidden;
  background-color: $white;
  border-radius: $border-radius-small;
  border: 1px solid $border-colour;
  width: var(--radix-select-trigger-width);
  max-height: var(--radix-select-content-available-height);
  box-shadow:
    0px 0.625rem 2.375rem -0.625rem rgba(22, 23, 24, 0.35),
    0px 0.625rem 1.25rem -1rem rgba(22, 23, 24, 0.2);
}

.ma-select-viewport {
  padding: 0.25rem;
  z-index: 10000;
}

.ma-select-label {
  padding: 0 1.5rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: $subtle;
}

.ma-select-item {
  font-size: $body-font-size;
  line-height: 1.2;
  color: $body-color;
  border-radius: $border-radius-small;
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem 0.5rem 1.5rem;
  position: relative;
  user-select: none;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-disabled] {
    color: $subtle;
    pointer-events: none;
  }

  &[data-highlighted] {
    outline: none;
    background-color: $primary-a;
    color: $white;
  }
}

.ma-select-item-indicator {
  position: absolute;
  left: 0;
  width: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.line-items {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.summary {
    gap: 2rem;
  }

  .show-all {
    @extend .body-font;

    padding-top: 1rem;
    align-self: center;
    color: $primary-a;
    cursor: pointer;
  }
}

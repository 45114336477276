@import '../../../common/assets/scss/typography';
@import '../../../common/assets/scss/variables';

.order-history {
  font-size: $body-font-size;

  tr:last-child {
    td {
      border-bottom: 0;
    }
  }

  td:first-child {
    padding-left: 0;
  }

  td:last-child {
    padding-right: 0;
  }

  p {
    margin-bottom: 0.25rem;
  }

  .history-event-messages {
    overflow-wrap: anywhere;
  }
}

.title {
  h4 {
    font-size: 1.125rem;
  }
}

@import '../../../common/assets/scss/variables';

.stock {
  padding: 0.5rem 0.625rem;
  color: $white;
  background-color: $secondary-colour-orange;
  margin-bottom: 0.75rem;

  &.disabled {
    background-color: $base-colour-dark-grey;
  }

  &.success {
    background-color: $success;
  }

  &.warning {
    background-color: $warning;
  }

  &.danger {
    background-color: $danger;
  }
}

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';

.basket-preview {
  width: 18.5rem;
  padding: 1.25rem;

  :global(.btn) {
    min-height: $ma-controls-min-height;
    width: 100%;
  }
}

.heading {
  font-family: $be-vietnam-pro;
  font-weight: 400;
  text-align: center;
  padding-bottom: 0.75rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid $border-colour;
}

.empty-basket-txt {
  font-size: $body-font-size;
  text-align: center;
  margin: 2rem auto;
}

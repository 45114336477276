@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.notification-alert {
  @extend .body-font;

  padding: 1.25rem;
  padding-right: 3rem;
  padding-left: 1rem;
  border: 1px solid $border-colour;
  border-radius: $border-radius-standard;
  margin-bottom: 1.25rem;

  &.unread {
    border-left-width: 0.25rem;
    border-left-style: solid;
    border-left-color: $danger;
  }

  :global(.btn-close) {
    background-size: 0.75rem;
    padding: 0;
    top: 1.25rem;
    right: 1.25rem;

    &:focus {
      box-shadow: none;
    }
  }

  .content {
    display: flex;
    font-size: $body-font-size;
    flex-direction: column;
    cursor: pointer;
  }

  .header {
    padding-bottom: 0.75rem;
  }

  .title {
    @extend .body-font;

    margin-bottom: 0;
    color: $primary-b;
  }

  .body {
    position: relative;
    min-height: 3rem;
    word-break: normal;
    overflow-wrap: anywhere;
  }

  &.expandable .body {
    max-height: 3rem;
    overflow: hidden;
    transition: max-height 0.3s;

    &.has-overflow::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 3rem;
      opacity: 1;
      transition: opacity 0.3s;
      background-color: transparent;
      background: linear-gradient(transparent, #fff);
    }
  }

  &.expandable.expanded .body {
    min-height: 3rem;
    max-height: 20rem;
  }

  .footer {
    @extend .label-font;

    padding-top: 0.75rem;
  }

  .toggle-expanded-btn {
    border: none;
    opacity: 0.5;
    background: transparent;
    position: absolute;
    bottom: 1.25rem;
    right: 0.875rem;
    z-index: 2;

    &:hover {
      opacity: 0.75;
    }
  }
}

@import '../../../common/assets/scss/variables';

.content {
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid $border-colour;
}

.account-settings {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
